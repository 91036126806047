var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    {
      class: _vm.classColor,
      staticStyle: { bottom: "unset" },
      attrs: { id: "core-footer", absolute: "" }
    },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { attrs: { align: "center", "no-gutters": "" } },
            [
              _vm._l(_vm.links, function(link, i) {
                return _c(
                  "v-col",
                  {
                    key: i,
                    staticClass: "text-center mb-sm-0",
                    attrs: { cols: "auto" }
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "mx-2",
                        attrs: {
                          fab: "",
                          text: "",
                          depressed: "",
                          small: "",
                          href: link.href,
                          target: "_blank"
                        }
                      },
                      [
                        link.text !== "MMS Twitter"
                          ? _c("v-icon", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(link.icon) +
                                  "\n          "
                              )
                            ])
                          : _c("img", {
                              staticStyle: {
                                width: ".25in",
                                "min-height": ".25in"
                              },
                              attrs: {
                                id: "yiv2015498803_x0000_i1030",
                                border: "0",
                                width: "24",
                                height: "24",
                                src:
                                  "https://ecp.yusercontent.com/mail?url=https%3A%2F%2Fcdn-images.mailchimp.com%2Ficons%2Fsocial-block-v2%2Foutline-light-twitter-48.png&t=1712619099&ymreqid=ed30bb03-693b-c10d-1c9e-3d016601e300&sig=BZyeYAQtg2QeFTKmQgOqYg--~D",
                                alt: "Twitter"
                              }
                            })
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _c("v-spacer", { staticClass: "hidden-sm-and-down" }),
              _c("v-col", { attrs: { cols: "12", md: "auto" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "text-body-1 font-weight-light pt-6 pr-10 pt-md-0 text-center"
                  },
                  [
                    _vm._v(
                      "\n          ©" +
                        _vm._s(_vm.currentYear) +
                        " Medical Marketing Service, Inc. | All rights reserved.\n        "
                    )
                  ]
                )
              ])
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }