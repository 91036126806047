<template>
  <v-footer
    id="core-footer"
    :class="classColor"
    absolute
    style="bottom: unset;"
  >
    <v-container>
      <v-row
        align="center"
        no-gutters
      >
        <v-col
          v-for="(link, i) in links"
          :key="i"
          class="text-center mb-sm-0"
          cols="auto"
        >
          <v-btn
            fab
            text
            depressed
            small
            :href="link.href"
            class="mx-2"
            target="_blank"
          >
            <v-icon v-if="link.text !== 'MMS Twitter'">
              {{ link.icon }}
            </v-icon>
            <img
              v-else
              id="yiv2015498803_x0000_i1030"
              border="0"
              width="24"
              height="24"
              style="width:.25in;min-height:.25in;"
              src="https://ecp.yusercontent.com/mail?url=https%3A%2F%2Fcdn-images.mailchimp.com%2Ficons%2Fsocial-block-v2%2Foutline-light-twitter-48.png&amp;t=1712619099&amp;ymreqid=ed30bb03-693b-c10d-1c9e-3d016601e300&amp;sig=BZyeYAQtg2QeFTKmQgOqYg--~D"
              alt="Twitter"
            >
            <!-- <font-awesome-icon v-else :icon="['fab', 'x-twitter']" /> -->
          </v-btn>
        </v-col>

        <v-spacer class="hidden-sm-and-down" />

        <v-col
          cols="12"
          md="auto"
        >
          <div class="text-body-1 font-weight-light pt-6 pr-10 pt-md-0 text-center">
            ©{{ currentYear }} Medical Marketing Service, Inc. | All rights reserved.
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import moment from 'moment'

export default {
  name: 'CoreFooter',

  props: {
    classColor: {
      type: String,
      default: 'tertiary'
    }
  },

  data: () => ({
    links: [
      {
        href: 'https://twitter.com/MMSemail',
        text: 'MMS Twitter',
        icon: 'mdi-twitter'
      },
      {
        href: 'https://www.facebook.com/mmsemail/',
        text: 'MMS Facebook',
        icon: 'mdi-facebook'
      },
      {
        href: 'https://www.linkedin.com/company/medical-marketing-service-inc--mms-/',
        text: 'MMS LinkedIn',
        icon: 'mdi-linkedin'
      }
    ]
  }),

  computed: {
    currentYear () {
      return moment().year()
    }
  }
}
</script>

<style lang="sass">
  #core-footer
    a
      color: #3c4858
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase
</style>
